<!--IINA-->
<section class="min-w-full mt-0 bg-white lg:mt-6 -z-2" id="henkilot">
  <div class="container px-2 mx-auto">
    <div
      class="flex flex-wrap py-16 mx-auto overflow-hidden text-center max-w-7xl lg:text-left"
    >
      <!--Left-->
      <div class="z-10 w-full lg:w-1/2">
        <div
          class="inline-block mx-auto sm:border-2 sm:ml-4 w-72 sm:w-80 lg:w-96 xl:w-100 border-secondary"
        >
          <img
            src="/static/iina.jpg"
            alt="Iina"
            class="relative -z-1 sm:right-4 sm:bottom-4"
          />
        </div>
      </div>
      <!--Right-->
      <div class="w-full lg:w-1/2">
        <div class="lg:ml-10">
          <h2
            class="mt-4 text-4xl font-bold sm:mt-0 sm:text-5xl lg:text-6xl font-cambon text-name"
          >
            Iina Liukkonen
          </h2>
          <p class="mt-8 text-base font-bold lg:text-lg font-ptsans">
            <svg width="40" height="5" class="inline-block">
              <line
                x1="0"
                x2="25"
                style="stroke:rgb(247,200,143);stroke-width:3"
              />
            </svg>Josefiinan omistaja
          </p>
          <p class="mt-4 text-base font-bold lg:text-lg font-ptsans">
            <svg width="40" height="5" class="inline-block">
              <line
                x1="0"
                x2="25"
                style="stroke:rgb(247,200,143);stroke-width:3"
              />
            </svg>Parturi-kampaaja, ripsiteknikko
          </p>
          <p class="mt-8 text-sm lg:text-base font-ptsans text-name">
            Valmistuin parturi-kampaajaksi vuonna 2011 Savonlinnan
            ammattikoulusta. Yrittäjä olen ollut jo vuodesta 2014 lähtien ja
            tällöin aloitin liiketyössä myös ripsienpidennyksien parissa.
            Josefiinan brändiä aloitettiin rakentamaan vuonna 2015. Liiketyössä
            arvostankin suuresti sitä että meillä asiakas palvellaan aina alusta
            loppuun asti yksilöllisesti. Olen todella kiitollinen sekä ylpeä
            että meidän Josefiinan tiimi koostuu rautaisista alan
            ammattilaisista!
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Niia-->
<section class="min-w-full mt-0 bg-white -z-2">
  <div class="container px-2 mx-auto">
    <div
      class="flex flex-wrap-reverse py-16 mx-auto overflow-hidden text-center max-w-7xl lg:text-left"
    >
      <!--Left-->
      <div class="w-full lg:w-1/2">
        <div class="">
          <h2
            class="mt-4 text-4xl font-bold sm:mt-0 sm:text-5xl lg:text-6xl font-cambon text-name"
          >
            Niia Heikkilä
          </h2>
          <p class="mt-8 text-base font-bold lg:text-lg font-ptsans">
            <svg width="40" height="5" class="inline-block">
              <line
                x1="0"
                x2="25"
                style="stroke:rgb(247,200,143);stroke-width:3"
              />
            </svg>Parturi-kampaaja - yrittäjä
          </p>
          <p class="mt-8 text-sm lg:text-base font-ptsans text-name">
            Hiusalalla olen ollut vuodesta 2019. Palveluihini kuuluu hiustenleikkaukset, partapalvelut sekä
            monipuolisesti eri värjäystyöt. Erityisesti raidoitus- ja vaalennustyöt, sekä pehmeät ja luonnolliset
            lopputulokset ovat lähellä sydäntäni..
          </p>
        </div>
      </div>
      <!--RIGHT-->
      <div class="relative z-10 w-full lg:w-1/2">
        <div
          class="inline-block lg:float-right border-secondary sm:border-2 sm:ml-4 w-72 sm:w-80 lg:w-96 xl:w-100"
        >
          <img
            src="/static/niia.jpg"
            alt="Niia"
            class="relative -z-1 sm:right-4 sm:bottom-4"
          />
        </div>
      </div>
    </div>
  </div>
</section>
<!--KAISA-->
<section class="min-w-full mt-0 bg-white lg:mt-6 -z-2" id="henkilot">
  <div class="container px-2 mx-auto">
    <div
      class="flex flex-wrap py-16 mx-auto overflow-hidden text-center max-w-7xl lg:text-left"
    >
      <!--Left-->
      <div class="z-10 w-full lg:w-1/2">
        <div
          class="inline-block mx-auto sm:border-2 sm:ml-4 w-72 sm:w-80 lg:w-96 xl:w-100 border-secondary"
        >
          <img
            src="/static/kaisa.jpg"
            alt="Kaisa"
            class="relative -z-1 sm:right-4 sm:bottom-4"
          />
        </div>
      </div>
      <!--Right-->
      <div class="w-full lg:w-1/2">
        <div class="lg:ml-10">
          <h2
            class="mt-4 text-4xl font-bold sm:mt-0 sm:text-5xl lg:text-6xl font-cambon text-name"
          >
          Kaisa Muinonen
          </h2>
          <p class="mt-8 text-base font-bold lg:text-lg font-ptsans">
            <svg width="40" height="5" class="inline-block">
              <line
                x1="0"
                x2="25"
                style="stroke:rgb(247,200,143);stroke-width:3"
              />
            </svg>SKY-kosmetologi, ripsiteknikko – yrittäjä
          </p>
          <p class="mt-8 text-sm lg:text-base font-ptsans text-name">
            Olen toiminut alalla vuodesta 2018 ja valmistunut kosmetologiksi Lahdessa Koulustuskeskus
            Salpauksesta. Nautin siitä että työni on monipuolista, mutta suurin intohimoni on ripsi- ja kulmatyöt sekä
            kasvohoidot. Haluan tehdä työni aina tarkasti ja asiakkaan toiveita kuunnellen.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Karoliina-->
<section class="min-w-full mt-0 bg-white -z-2">
  <div class="container px-2 mx-auto">
    <div
      class="flex flex-wrap-reverse py-16 mx-auto overflow-hidden text-center max-w-7xl lg:text-left"
    >
      <!--Left-->
      <div class="w-full lg:w-1/2">
        <div class="">
          <h2
            class="mt-4 text-4xl font-bold sm:mt-0 sm:text-5xl lg:text-6xl font-cambon text-name"
          >
          Karoliina Manninen
          </h2>
          <p class="mt-8 text-base font-bold lg:text-lg font-ptsans">
            <svg width="40" height="5" class="inline-block">
              <line
                x1="0"
                x2="25"
                style="stroke:rgb(247,200,143);stroke-width:3"
              />
            </svg>Parturi-kampaaja - yrittäjä
          </p>
          <p class="mt-8 text-sm lg:text-base font-ptsans text-name">
            <!--Teksti-->
          </p>
        </div>
      </div>
      <!--RIGHT-->
      <div class="relative z-10 w-full lg:w-1/2">
        <div
          class="inline-block lg:float-right border-secondary sm:border-2 sm:ml-4 w-72 sm:w-80 lg:w-96 xl:w-100"
        >
          <img
            src="/static/place.jpg"
            alt="Karoliina"
            class="relative -z-1 sm:right-4 sm:bottom-4"
          />
        </div>
      </div>
    </div>
  </div>
</section>
<!--Rosalina-->
<section class="min-w-full mt-0 bg-white lg:mt-6 -z-2" id="henkilot">
  <div class="container px-2 mx-auto">
    <div
      class="flex flex-wrap py-16 mx-auto overflow-hidden text-center max-w-7xl lg:text-left"
    >
      <!--Left-->
      <div class="z-10 w-full lg:w-1/2">
        <div
          class="inline-block mx-auto sm:border-2 sm:ml-4 w-72 sm:w-80 lg:w-96 xl:w-100 border-secondary"
        >
          <img
            src="/static/place.jpg"
            alt="Rosalina"
            class="relative -z-1 sm:right-4 sm:bottom-4"
          />
        </div>
      </div>
      <!--Right-->
      <div class="w-full lg:w-1/2">
        <div class="lg:ml-10">
          <h2
            class="mt-4 text-4xl font-bold sm:mt-0 sm:text-5xl lg:text-6xl font-cambon text-name"
          >
          Rosalina Aholainen
          </h2>
          <p class="mt-8 text-base font-bold lg:text-lg font-ptsans">
            <svg width="40" height="5" class="inline-block">
              <line
                x1="0"
                x2="25"
                style="stroke:rgb(247,200,143);stroke-width:3"
              />
            </svg>Parturi-kampaaja - yrittäjä
          </p>
          <p class="mt-8 text-sm lg:text-base font-ptsans text-name">
            <!--Teksti-->
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
