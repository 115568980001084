<!--NAV-->
<div class="w-full pt-4 border-gray-700 bg-primary lg:pt-0 lg:border-b">
  <div class="container px-2 mx-auto">
    <!--NAV-->
    <nav class="flex mx-auto sm:justify-between max-w-7xl sm:items-center">
      <!--Left-->
      <div class="items-center mx-auto lg:mx-0 w-44">
        <img src="/static/logo.svg" alt="josefiinalogo" class="w-full" />
      </div>

      <!--Right-->
      <div
        class="flex-row items-center hidden font-bold text-white uppercase lg:flex"
      >
        <a
          href="#henkilot"
          class="py-8 mr-8 border-t-4 border-primary hover:border-secondary hover:text-secondary"
          >henkilöt</a
        >
        <a
          href="#hinnasto"
          class="py-8 mr-8 border-t-4 border-primary hover:border-secondary hover:text-secondary"
          >hinnasto</a
        >
        <a
          href="#galleria"
          class="py-8 mr-8 border-t-4 border-primary hover:border-secondary hover:text-secondary"
          >galleria</a
        >
        <a
          href="#tiedot"
          class="py-8 mr-8 border-t-4 border-primary hover:border-secondary hover:text-secondary"
          >yhteystiedot</a
        >
        <a
          target="_blank"
          href="https://booksalon.fi/kauneuskulma-josefiina"
          class="px-4 py-2 bg-secondary text-button hover:bg-btnhover"
          >ajanvaraus</a
        >
      </div>
    </nav>
  </div>
</div>
