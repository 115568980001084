<section class="min-w-full bg-primary">
  <div class="container px-2 mx-auto">
    <div
      class="flex flex-wrap py-16 mx-auto overflow-hidden text-center max-w-7xl lg:text-left"
    >
      <!--Left-->
      <div class="w-full lg:w-1/2">
        <p class="pb-6 text-sm font-bold uppercase text-secondary">
          PARTURI-KAMPAAMOPALVELUT, SKY-KOSMETOLOGI JA RIPSIENPIDENNYKSET
        </p>
        <h1
          class="pb-8 text-4xl font-bold uppercase leading-none text-white font-cambon sm:text-6xl lg:text-7xl xl:text-8xl lg:pb-16"
        >
        Josefiina Mikkeli
        </h1>
        <p class="pb-16 text-base text-white font-ptsans lg:text-xl">
          Sijaitsemme Mikkelin keskustassa torin läheisyydessä. Olemme avoinna
          sopimuksen mukaan viikon jokaisena päivänä, myös arki-iltoina ja
          viikonloppuisin!
        </p>
        <a
          target="_blank"
          href="https://booksalon.fi/kauneuskulma-josefiina"
          class="px-6 py-3 text-xs font-bold uppercase sm:text-sm lg:text-base bg-secondary text-button hover:bg-btnhover"
          >siirry ajanvaraukseen</a
        >
      </div>
      <!--Right-->
      <div class="relative hidden lg:w-1/2 lg:-mb-56 lg:block">
        <div
          class="inline-block float-right border-2 border-secondary w-96 xl:w-100"
        >
          <img
            src="/static/liike.jpg"
            alt="liike"
            class="relative z-10 right-4 bottom-4"
          />
        </div>
        <div
          class="relative invisible inline-block border-2 lg:visible w-60 bottom-52 xl:w-80 xl:bottom-64 border-secondary"
        >
          <img
            src="/static/tuoli.jpg"
            alt="liike"
            class="relative z-20 left-4 top-4"
          />
        </div>
      </div>
    </div>
  </div>
</section>
