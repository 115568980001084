<footer class="text-white bg-button" id="tiedot">
  <div
    class="container flex flex-col flex-wrap px-5 py-24 mx-auto max-w-7xl md:items-center lg:items-start md:flex-row md:flex-nowrap"
  >
    <div class="flex-shrink-0 w-64 mx-auto text-center md:mx-0 md:text-left">
      <h1 class="mt-2 text-3xl font-semibold text-white sm:text-4xl">
        Yhteystiedot
      </h1>
    </div>
    <div
      class="flex flex-wrap flex-grow mt-10 -mb-10 text-center md:pl-20 md:mt-0 md:text-left"
    >
      <div class="w-full px-4 lg:w-1/3 md:w-1/2">
        <h2
          class="mb-3 text-sm font-bold tracking-widest md:text-base text-secondary font-cambon"
        >Osoite</h2>
        <nav class="mb-10 list-none">
          <li>
            <p class="font-ptsans">Mikonkatu 5 LH 11</p>
          </li>
          <li>
            <p class="font-ptsans">50100 Mikkeli</p>
          </li>
        </nav>
      </div>
      <div class="w-full px-4 lg:w-1/3 md:w-1/2">
        <h2
          class="mb-3 text-sm font-bold tracking-widest md:text-base text-secondary font-cambon"
        >Ota yhteyttä</h2>
        <nav class="mb-10 list-none">
          <li>
            <p class="font-ptsans">p. 0442 351 124</p>
          </li>
          <li>
            <p class="font-ptsans">josefiinamikkeli@gmail.com</p>
          </li>
        </nav>
      </div>
      <div class="w-full px-4 lg:w-1/3 md:w-1/2">
        <h2
          class="mb-3 text-sm font-bold tracking-widest md:text-base text-secondary font-cambon"
        >Sosiaalinen media</h2>
        <nav class="mb-10 list-none">
          <li>
            <a
              target="_blank"
              href="https://www.facebook.com/kauneuskulmajosefiina/"
              class="font-ptsans hover:text-secondary">Facebook</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.instagram.com/josefiinamikkeli"
              class="font-ptsans hover:text-secondary">Instagram</a
            >
          </li>
        </nav>
      </div>
    </div>
  </div>
</footer>
