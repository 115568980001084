<!--HINNASTO-->
<section class="min-w-full mt-0 bg-button lg:mt-6" id="hinnasto">
  <div class="container px-2 mx-auto">
    <h2
      class="pt-10 mx-auto text-3xl font-semibold text-center text-white max-w-7xl sm:text-4xl lg:text-left lg:text-6xl font-cambon"
    >
      Hinnasto
    </h2>
    <div
      class="flex flex-wrap pt-10 pb-16 mx-auto overflow-hidden text-center max-w-7xl lg:text-left"
    >
      <!--Left-->

      <div class="w-full lg:w-1/2">
        <h3
          class="mb-4 text-xl sm:text-2xl lg:text-left font-cambon text-secondary"
        >
          Hiustenleikkaukset
        </h3>
        <table
          class="mx-auto text-sm text-white lg:float-left sm:text-base w-72 sm:w-100 lg:mb-10 font-ptsans"
        >
          <tr class="border-b border-table">
            <td class="text-left h-9">Parturi 30 min</td>
            <td class="text-right">35 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9">Kampaamo 45 min</td>
            <td class="text-right">45 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9">Kampaamo 60 min</td>
            <td class="text-right">59 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9">Hiustenleikkaus muodonmuutos</td>
            <td class="text-right">59 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9">Hiustenleikkaus koneajelu</td>
            <td class="text-right">25 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9">Lasten hiustenleikkaus (max 7-vuotta)</td>
            <td class="text-right">27 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9">Otsahiusten leikkaus</td>
            <td class="text-right">20 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9">Niska- tai sivusiilen leikkaus</td>
            <td class="text-right">20 €</td>
          </tr>
        </table>
        <h3
          class="mt-10 mb-4 text-xl sm:text-2xl lg:text-left font-cambon text-secondary"
        >
          Hiustenvärjäykset
        </h3>
        <table
          class="mx-auto text-sm text-white lg:float-left sm:text-base w-72 sm:w-100 lg:mb-10 font-ptsans"
        >
          <tr class="border-b border-table">
            <td class="text-left h-9">Väri tyveen alle 2cm</td>
            <td class="text-right">alk. 105 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9">Väri lyhyet </td>
            <td class="text-right">alk. 105 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9">Väri puolipitkät (max olkapäille)</td>
            <td class="text-right">alk. 115 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9">Väri pitkät</td>
            <td class="text-right">alk. 125 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9">Moniväri lyhyet</td>
            <td class="text-right">alk. 115 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9">Moniväri puolipitkät (max olkapäille)</td>
            <td class="text-right">alk. 125 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9">Moniväri pitkät</td>
            <td class="text-right">alk. 145 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9">Tyvivaalennus alle 2cm</td>
            <td class="text-right">alk. 125 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="w-2/3 text-left h-9"
              >Vaalennukset/Raidoitukset/Erikoistyöt</td
            >
            <td class="text-right">alk. 62 € / h</td>
          </tr>
        </table>
        <h3
          class="mt-10 mb-4 text-xl sm:text-2xl lg:w-52 lg:text-left font-cambon text-secondary"
        >
          Kampaukset
        </h3>
        <table
          class="mx-auto text-sm text-white lg:float-left sm:text-base w-72 sm:w-100 lg:mb-10 font-ptsans"
        >
          <tr class="border-b border-table">
            <td class="text-left h-9">Kampaukset </td>
            <td class="text-right">alk. 40 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9"> Hääkampaus sis. Koekampauksen </td>
            <td class="text-right">190 €</td>
          </tr>
        </table>
        <h3
          class="mt-10 mb-4 text-xl sm:text-2xl lg:text-left font-cambon text-secondary"
        >
          Ripsienpidennykset
        </h3>
        <table
          class="mx-auto text-sm text-white lg:float-left sm:text-base w-72 sm:w-100 font-ptsans"
        >
          <tr class="border-b border-table">
            <td class="text-left h-9"> Uudet volume ripsienpidennykset </td>
            <td class="text-right">135 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="w-2/3 text-left h-9">Volume huollot</td>
            <td class="text-right">55 - 95 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9"> Uudet klassiset ripsienpidennykset </td>
            <td class="text-right">120 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="w-2/3 text-left h-9">Klassinen huolto</td>
            <td class="text-right">55 - 80 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9">Uudet Hybrid ripsienpidennykset</td>
            <td class="text-right">135 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9">Hybrid Huolto</td>
            <td class="text-right">55 - 95 €</td>
          </tr>
          <tr class="border-b border-table">
            <td class="text-left h-9">Ripsienpidennysten poisto </td>
            <td class="text-right">35 €</td>
          </tr>
        </table>
      </div>
      <!--Right-->
      <div class="w-full lg:w-1/2">
        <div class="lg:float-right">
          <h3
            class="mt-10 mb-4 text-xl sm:text-2xl lg:text-left font-cambon text-secondary"
          >
            Kasvohoidot
          </h3>
          <table
            class="mx-auto text-sm text-white lg:float-left sm:text-base w-72 sm:w-100 lg:mb-10 font-ptsans"
          >
            <tr class="border-b border-table">
              <td class="text-left h-9"> Perinteinen kasvohoito 1,5t </td>
              <td class="text-right">99 €</td>
            </tr>
            <tr class="border-b border-table">
              <td class="w-2/3 text-left h-9">Perinteinen ihonpuhdistus</td>
              <td class="text-right">69 €</td>
            </tr>
            <tr class="border-b border-table">
              <td class="text-left h-9">Lempeä kasvohoito</td>
              <td class="text-right">79 €</td>
            </tr>
            <tr class="border-b border-table">
              <td class="w-2/3 text-left h-9"
                >Tehokosteuttava erikoiskasvohoito</td
              >
              <td class="text-right">92 €</td>
            </tr>
            <tr class="border-b border-table">
              <td class="text-left h-9">Elvyttävä anti-age erikoiskasvohoito</td
              >
              <td class="text-right">121 €</td>
            </tr>
            <tr class="border-b border-table">
              <td class="text-left h-9"
                >Herkän/Couperosa ihon erikoiskasvohoito</td
              >
              <td class="text-right">112 €</td>
            </tr>
            <tr class="border-b border-table">
              <td class="text-left h-9">Vitamiini erikoiskasvohoito</td>
              <td class="text-right">121 €</td>
            </tr>
            <tr class="border-b border-table">
              <td class="text-left h-9">Ultraäänikuorinta</td>
              <td class="text-right">55 - 82 €</td>
            </tr>
            <tr class="border-b border-table">
              <td class="text-left h-9">Timanttihionta</td>
              <td class="text-right">57 - 87 €</td>
            </tr>
            <tr class="border-b border-table">
              <td class="text-left h-9">Kosmetologin valitsema kasvohoito</td>
              <td class="text-right">99 €</td>
            </tr>
          </table>
          <h3
            class="mt-10 mb-4 text-xl sm:text-2xl lg:text-left font-cambon text-secondary"
          >
            Jalkahoidot
          </h3>
          <table
            class="mx-auto text-sm text-white lg:float-left sm:text-base w-72 sm:w-100 lg:mb-10 font-ptsans"
          >
            <tr class="border-b border-table">
              <td class="text-left h-9">Jalkahoidot</td>
              <td class="text-right">Alk. 65 €</td>
            </tr>
          </table>
          <h3
            class="mt-10 mb-4 text-xl sm:text-2xl lg:text-left font-cambon text-secondary"
          >
            Sokeroinnit
          </h3>
          <table
            class="mx-auto text-sm text-white lg:float-left sm:text-base w-72 sm:w-100 lg:mb-10 font-ptsans"
          >
            <tr class="border-b border-table">
              <td class="text-left h-9">Brasilialainen sokerointi, naiset</td>
              <td class="text-right">57 €</td>
            </tr>
            <tr class="border-b border-table">
              <td class="text-left h-9"
                >Brasilialainen sokerointi, naiset 1.kerta</td
              >
              <td class="text-right">68 €</td>
            </tr>
            <tr class="border-b border-table">
              <td class="text-left h-9">Bikiniraja/sääret/reidet</td>
              <td class="text-right">38 €/40 €/40€</td>
            </tr>
            <tr class="border-b border-table">
              <td class="text-left h-9">Käsivarret, kainalot, kasvot</td>
              <td class="text-right">35 €/28 €/30€</td>
            </tr>
          </table>
          <h3
            class="mt-10 mb-4 text-xl sm:text-2xl lg:text-left font-cambon text-secondary"
          >
            Muut
          </h3>
          <table
            class="mx-auto text-sm text-white lg:float-left sm:text-base w-72 sm:w-100 lg:mb-10 font-ptsans"
          >
            <tr class="border-b border-table">
              <td class="w-2/3 text-left h-9">
                Kulmien värjäys & muotoilu + ripsien värjäys
              </td>
              <td class="text-right">
                <p>40 €</p>
              </td>
            </tr>
            <tr class="border-b border-table">
              <td class="w-2/3 text-left h-9"> Kulmien värjäys & muotoilu </td>
              <td class="text-right">
                <p>30 €</p>
              </td>
            </tr>
            <tr class="border-b border-table">
              <td class="w-2/3 text-left h-9"> Kulmien värjäys </td>
              <td class="text-right">
                <p>20 €</p>
              </td>
            </tr>
            <tr class="border-b border-table">
              <td class="w-2/3 text-left h-9"> Kulmien muotoilu </td>
              <td class="text-right">
                <p>16 €</p>
              </td>
            </tr>
          </table>
          <p class="mx-auto my-10 text-sm w-72 sm:w-100 font-ptsans text-small">
            NETTIAJANVARAUKSESTAMME LÖYDÄT AJANTASAISIMMAN JA KATTAVIMMAN
            PALVELUVALIKOIMAMME HINNASTOINEEN.
          </p>
          <p class="mx-auto my-10 text-sm w-72 sm:w-100 font-ptsans text-small">
            Pidätämme oikeuden hinnanmuutoksiin. Peruutukset VAIN puhelimitse
            viimeistään 24 tuntia aikaisemmin. Viimehetken peruutuksista sekä
            käyttämättä jääneistä varauksista veloitamme 100% varatun palvelun
            arvosta. Emme tee ripsienpidennyksiä alle 18-vuotiaille.
          </p>
          <a
            target="_blank"
            href="https://booksalon.fi/kauneuskulma-josefiina"
            class="px-6 py-3 text-xs font-bold uppercase sm:text-sm bg-secondary text-button hover:bg-btnhover"
            >siirry ajanvaraukseen</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
